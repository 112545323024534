import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';
import { Brand, LOGO_FOLDER, LOGO_MAPPING, LogoType } from '@design-system';
import { Nil } from '@model';
import { isNil } from 'lodash-es';

@Component({
  selector: 'etn-logo',
  templateUrl: `./logo.component.html`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent implements OnChanges {
  @Input() public brand: Brand | Nil;
  @Input() public type: LogoType | Nil;
  @Input() public height: number | Nil = undefined;

  public src = '';
  public svgStyle: Record<string, any> = this.getSvgStyle(this.height);

  public ngOnChanges(): void {
    if (isNil(this.brand)) {
      this.src = '';
    } else {
      this.src = `${LOGO_FOLDER}/${this.brand}/${
        LOGO_MAPPING[this.brand][this.type ?? LogoType.Primay]
      }`;
    }
    this.svgStyle = this.getSvgStyle(this.height);
  }

  private getSvgStyle(height: number | Nil): Record<string, any> {
    if (isNil(height)) {
      return {
        display: 'block',
      };
    }
    return {
      display: 'block',
      'height.px': height,
    };
  }
}
